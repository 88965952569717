import React from 'react';

const HeroTitle = ({ text }) => (
  <h1 className="title-dark text-white mb-3">{text}</h1>
);

const HeroSubtitle = ({ text }) => (
  <h2 className="mx-auto text-white-50">{text}</h2>
);

const HeroRequestButton = () => (
  <div className="mt-4 pt-2">
    <a href="#contactmessage" className="btn btn-light">
      <i className="mdi mdi-email"></i> Request a Free Consultation
    </a>
  </div>
);

export { HeroTitle, HeroSubtitle, HeroRequestButton };