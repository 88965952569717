import React from 'react';
import styled from "styled-components";
import SectionTitle from 'components/sections/title';

const HR = styled.hr`
  margin: 0;
  width: 80%;
`;

const ProcessItem = ({ title, text, step }) => (
  <div className="work-process shadow-lg p-lg-4 p-3 card rounded h-100">
    <div className="d-block" style={{ height: "80%" }}>
      <h4 className="title">{title}</h4>
      <p className="">{text}</p>
    </div>
    <div className="d-block">
      <HR />
      <ul className="list-unstyled mb-0 mt-2">
        <li className="step-icon float-right">
          <i
            className={`mdi ${
              step.last ? 'mdi-check-all' : 'mdi-chevron-double-right'
            }`}
          ></i>
        </li>
        <li className="step font-weight-bold">{step.text}</li>
      </ul>
    </div>
  </div>
);


const OutProcess = () => (
  <section className="section border-top bg-light">
    <div className="container">
      <SectionTitle>Main Stages of Development Process</SectionTitle>

      <div className="row">
        <div className="col-md-6 col-xl-4 mt-4 pt-2">
          <ProcessItem
            title="Preliminary Discussion"
            text="Say hello. Getting the project vision, goals, use cases and requirements."
            step={{ text: 'PREPARATION' }}
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4 pt-2">
          <ProcessItem
            title="Initial Analysis"
            text="Analysing the requirements. Project scope and budget."
            step={{ text: 'PREPARATION' }}
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4 pt-2">
          <ProcessItem
            title="Planning & Designing"
            text="Designing in diagrams, components interaction, data model. Managing the risks."
            step={{ text: 'ACTIVE' }}
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4 pt-2">
          <ProcessItem
            title="Prototyping & Development"
            text="Refining the backlog. Iterative development. Demo release. Measure the feedback."
            step={{ text: 'ACTIVE' }}
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4 pt-2">
          <ProcessItem
            title="Deployment & Release"
            text="Deploying and adapting to production environment."
            step={{ text: 'FINISHING', last: true }}
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4 pt-2">
          <ProcessItem
            title="Monitoring & Support"
            text="Monitoring the product in production, providing maintenance and support."
            step={{ text: 'DELIVERED', last: true }}
          />
        </div>
      </div>
    </div>
  </section>
);

export default OutProcess;