import React from "react";
import SectionTitle from 'components/sections/title';

const Feature = ({ title, text, icon }) => (
  <div className="features pt-4 pb-4">
    <div className="d-flex align-items-center justify-content-center-xs">
      <span className="icon text-info mr-3">{icon}</span>

      <div className="content d-block">
        <p className="card-title text-dark mb-0">{title}</p>
      </div>
    </div>
  </div>
);

const Values = () => (
  <section className="section border-top">
    <div className="container">
      <SectionTitle>Our Values</SectionTitle>

      <div className="row justify-content-center align-items-center">
        <div className="col-lg-3 col-12 col-sm-6">
          <Feature
            title="Responsibility"
            text="composed in a pseudo-Latin language which more or less pseudo-Latin
        language corresponds."
            icon={<i className="mdi mdi-check-decagram" />}
          />
        </div>

        <div className="col-lg-3 col-12 col-sm-6">
          <Feature
            title="Perseverance"
            text="
                composed in a pseudo-Latin language which more or less
                pseudo-Latin language corresponds.
          "
            icon={<i className="mdi mdi-check-decagram" />}
          />
        </div>

        <div className="col-lg-3 col-12 col-sm-6">
          <Feature
            title="Persistence"
            text="
                composed in a pseudo-Latin language which more or less
                pseudo-Latin language corresponds.
          "
            icon={<i className="mdi mdi-check-decagram" />}
          />
        </div>

        <div className="col-lg-3 col-12 col-sm-6">
          <Feature
            title="Self Discipline"
            text="
                composed in a pseudo-Latin language which more or less
                pseudo-Latin language corresponds.
          "
            icon={<i className="mdi mdi-check-decagram" />}
          />
        </div>

        <div className="col-lg-3 col-12 col-sm-6">
          <Feature
            title="Growth Mindset"
            text="
                composed in a pseudo-Latin language which more or less
                pseudo-Latin language corresponds.
          "
            icon={<i className="mdi mdi-check-decagram" />}
          />
        </div>

        <div className="col-lg-3 col-12 col-sm-6">
          <Feature
            title="Positive vibes"
            text="
                composed in a pseudo-Latin language which more or less
                pseudo-Latin language corresponds.
          "
            icon={<i className="mdi mdi-cards-heart icon" />}
          />
        </div>

        {/* <div className="col-lg-4 col-md-4 col-12 pt-2 pt-sm-0 text-center text-md-right">
          <img src="images/app/feature.png" className="img-fluid" alt="" />
        </div> */}
      </div>
    </div>
  </section>
);

export default Values;