/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import backEndDevIcon from "images/icon/Back-end Development.png"
import codeReviewIcon from "images/icon/Code Review.png"
import CustomWebAutomationIcon from "images/icon/Custom Web Automations.png"
import DataAnalyticsIcon from "images/icon/Data Analytics.png"
import FullStackWebDevIcon from "images/icon/Full-stack Web Development.png"
import mvpProtypingIcon from "images/icon/MVP & Prototyping.png"
import ResearchNDevIcon from "images/icon/Research & Development.png"
import SolvingComplexIssuesIcon from "images/icon/Solving Complex Issues.png"

const FeatureCard = styled.div`
  && {
  }
`;

const Feature = ({ icon, title, text, href }) => (
  <FeatureCard className="card course-feature shadow-lg text-center bg-white rounded p-3 py-4">
    <div className="icon">
      <img
        style={{ height: '150px', width: '150px' }}
        src={icon}
        alt="Icon"
        className="avatar"
      />
    </div>

    <h3 className="px-5">
      <span className="text-dark card-title-2">{title}</span>
    </h3>
  </FeatureCard>
);

const WhatWeDo = () => (
  <section className="section">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-xl-4 mt-4">
          <Feature
            icon={FullStackWebDevIcon}
            title="Full-stack Web Development"
            href="#someref"
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4">
          <Feature
            icon={backEndDevIcon}
            title="Back-end Development"
            href="#someref"
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4">
          <Feature
            icon={mvpProtypingIcon}
            title="MVP & Prototyping"
            href="#someref"
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4">
          <Feature
            icon={SolvingComplexIssuesIcon}
            title="Solving Challenging Tasks"
            href="#someref"
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4">
          <Feature
            icon={CustomWebAutomationIcon}
            title="Custom Web Automations"
            href="#someref"
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4">
          <Feature icon={DataAnalyticsIcon} title="Data Analytics" href="#someref" />
        </div>
        <div className="col-md-6 col-xl-4 offset-xl-2 mt-4">
          <Feature
            icon={ResearchNDevIcon}
            title="Research & Development"
            href="#someref"
          />
        </div>
        <div className="col-md-6 col-xl-4 mt-4">
          <Feature icon={codeReviewIcon} title="Code Review" href="#someref" />
        </div>
      </div>
    </div>
  </section>
);

export default WhatWeDo;