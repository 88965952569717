import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import styled from "styled-components";
import backgroundImage from 'images/enterprise.jpg';
import Shape from "components/shape";
import { HeroTitle, HeroSubtitle, HeroRequestButton } from 'components/sections/hero';
import WhatWeDo from 'components/sections/index/WhatWeDo';
import OurProcess from 'components/sections/index/OurProcess';
import Values from 'components/sections/index/Values';
import Solutions from 'components/sections/index/Solutions';
import Footer from 'components/footer';

const RadialGradient = styled.div`
  position: absolute;
  background: radial-gradient(39% 51%, #00b3ae 0, #043454 100%);
  height: 200%;
  top: 0;
  width: 180%;
  left: -40%;
`;

const HeroSection = ({ title, text }) => (
  <section className="section-xl full-height bg-home overflow-hidden w-100">
    <RadialGradient />
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="title-heading text-center mt-4">
            <HeroTitle text={title} />
            <HeroSubtitle text={text} />
            <HeroRequestButton />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Index = ({ data }) => (
  <Layout>
    <HeroSection
      title="Custom Web Applications Development"
      text="We help Individuals, Startups & Enterprises"
    />
    <Shape />
    <WhatWeDo />
    <OurProcess />
    <Values />
    {/* <Solutions /> */}
    <Footer />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
